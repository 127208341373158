import 'tailwindcss/tailwind.css';
import Amplify from 'aws-amplify';
import awsconfig from '../src/aws-exports';
import 'styles/file-drop.css'
import 'styles/tooltip.css'
import 'styles/globals.css'
import 'styles/Tailwindcssunreset.scss';
import { useEffect } from 'react';
import { appWithTranslation } from 'next-i18next';
import { CookiesProvider } from "react-cookie"
Amplify.configure(awsconfig);
const MyApp = ({ Component, pageProps }) => {
  useEffect( () => { document.querySelector("body").classList.add("overflow-x-hidden") } );
  useEffect( () => { document.querySelector("body").classList.add("sm:overflow-x-auto") } );
  useEffect( () => { document.querySelector("body").classList.add("min-h-screen") } );
  useEffect( () => { document.getElementById('__next').classList.add("min-h-screen") } );
  return (
    <CookiesProvider>
      <Component {...pageProps} />
    </CookiesProvider>
  )
}

export default appWithTranslation(MyApp);
